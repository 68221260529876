type TinitialState = {
  homeDashboard: any;
  books: any;
  cart: any;
  teachers: any;
  singleTeacher: any;
  externalCodes: any;
  profile: any;
  invoices: any;
  wallet: any;
  subjects: any;
  mySubjects: any;
  myCourses: any;
  singleBook: any;
  singleExternalCode: any;
  courses: any;
  singleCourse: any;
  myCourse: any;
  myExams: any;
  chartByMonth: any;
  fullExams: any;
  singleFullExam: any;
  myFullExams: any;
  home: any;
  checkInvoice: any,
};

export const initialValue: TinitialState = {
  homeDashboard: {},
  books: [],
  cart: {},
  teachers: [],
  singleTeacher: {},
  externalCodes: [],
  profile: {},
  invoices: {},
  wallet: {},
  subjects: [],
  mySubjects: [],
  myCourses: {},
  myCourse: {},
  myExams: [],
  singleBook: {},
  singleExternalCode: {},
  courses: [],
  singleCourse: {},
  chartByMonth: {},
  fullExams: [],
  singleFullExam: {},
  myFullExams: [],
  home: {},
  checkInvoice: {},
};
